import React, { useState, useContext } from 'react'
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  Button,
  useToast,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react'

import ContactContext from '../context/contact-context'

const ContactForm = ({ nameLabel, business, telephone }) => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tel, setTel] = useState('')
  const [businessName, setBusinessName] = useState('')
  const [error, setError] = useState('')
  const [previousError, setPreviousError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useContext(ContactContext)
  const [processingMessage, setProcessingMessage] = useState('')
  const toast = useToast()
  const formToastId = 'form-warning-toast'

  function wait(ms = 0) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms)
    })
  }

  async function handleSubmit(event) {
    event.preventDefault()
    console.log('submitted')
    setLoading(true)
    setProcessingMessage('Sending message')

    const res = await fetch(`${process.env.GATSBY_SERVERLESS_BASE}/contact`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        businessName: businessName,
        message: message,
        tel: tel,
        email: email,
      }),
    })
    const text = JSON.parse(await res.text())

    if (res.status >= 400 && res.status < 600) {
      setError(text.message)
      setProcessingMessage('Sending failed')
      await wait(2000)
      setLoading(false)
    } else {
      setProcessingMessage('Message sent successfully')
      console.log('Completed successfully: ' + name)
      await wait(2000)
      setLoading(false)
    }
  }

  function showToast(error) {
    if (!toast.isActive(formToastId)) {
      toast({
        id: formToastId,
        title: 'Something went wrong.',
        description: error,
        status: 'error',
        isClosable: true,
      })
    }
    setPreviousError(error)
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <FormControl isDisabled={loading} mb={4} isRequired>
        <FormLabel htmlFor="name">Name</FormLabel>

        <Input
          type="text"
          name="name"
          id="name"
          placeholder={nameLabel ? nameLabel : 'Your name'}
          onBlur={(event) => setName(event.target.value)}
        />
      </FormControl>
      {business && (
        <FormControl isDisabled={loading} mb={4} isRequired>
          <FormLabel htmlFor="businessName">Business Name</FormLabel>

          <Input
            type="text"
            name="businessName"
            id="businessName"
            placeholder="Your business name"
            onBlur={(event) => setBusinessName(event.target.value)}
          />
        </FormControl>
      )}
      <FormControl isDisabled={loading} mb={4} isRequired>
        <FormLabel htmlFor="email">Email</FormLabel>

        <Input
          type="text"
          name="email"
          id="email"
          placeholder="Your email"
          onBlur={(event) => setEmail(event.target.value)}
        />
        {!business && (
          <FormHelperText>We'll never share your email.</FormHelperText>
        )}
      </FormControl>
      {telephone && (
        <FormControl isDisabled={loading} mb={4}>
          <FormLabel htmlFor="tel">Telephone</FormLabel>

          <NumberInput
            type="text"
            name="tel"
            id="tel"
            placeholder="Your telephone number"
            onBlur={(event) => setTel(event.target.value)}
          >
            <NumberInputField />
          </NumberInput>
        </FormControl>
      )}
      <FormControl isDisabled={loading} mb={4}>
        <FormLabel htmlFor="message">Message</FormLabel>

        <Textarea
          type="text"
          name="message"
          id="message"
          placeholder="Your message"
          onBlur={(event) => setMessage(event.target.value)}
        />
      </FormControl>
      <FormControl disabled={loading}>
        <Button type="submit" colorScheme="brand.green">
          {loading ? processingMessage : 'Send'}
        </Button>
      </FormControl>
      {error !== previousError && showToast(error)}
    </form>
  )
}

export default ContactForm
