import * as React from 'react'
import {
  Container,
  Box,
  Text,
  SimpleGrid,
  Heading,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react'
import Layout from '../components/layout'
import ContactForm from '../components/contact'
import Seo from '../components/seo'

const IndexPage = () => {
  const headingColor = useColorModeValue(`headingColor`, `dark.headingColor`)

  return (
    <Layout>
      <Seo
        title="Trade & Wholesale for Food Retailers | Mud Foods"
        description="Calling all fine food retailers and quality eateries, if you are interested in working with Mud Foods, get in touch today!"
      />
      <Container py={20}>
        <SimpleGrid columns={[1, 1, 1, 2]} spacing={10}>
          <Box>
            <Stack spacing={4}>
              <Heading as="h1" color={headingColor}>
                Calling fine food retailers and quality eateries!
              </Heading>
              <Text>
                We’re Mud Foods - an artisan pie maker from the South Downs.
              </Text>

              <Text>
                To produce the finest pies and pastries, we source the finest
                quality ingredients, sourced as locally as possible, such as
                hand-cut, grass-fed matured beef, free-range chicken and top
                quality lamb, as well as organic milk and cream from the famous
                Goodwood Estate – quality without compromise.
              </Text>

              <Text>
                Mud Foods has won 129 awards at the last count, mostly at the
                British Pie Awards and Great Taste Awards, and has a national
                loyal fan base of Mud pie lovers!
              </Text>

              <Text>
                Currently distributing throughout the whole of the South East
                and London but also throughout most of mainland UK via our
                distributions partners.
              </Text>
              <Text>
                Interested? Then please do contact us for more details!
              </Text>

              <Heading as="h5" size="sm">
                - The Mud Team
              </Heading>
            </Stack>
          </Box>
          <Box p={0} data-name="product-image-box">
            <Heading as="h3" size="md" mb={2}>
              Would you like to sell Mud?
            </Heading>
            <Heading as="h4" size="sm" mb={4}>
              Use this form and get the pie rolling
            </Heading>
            <ContactForm business telephone />
          </Box>
        </SimpleGrid>
      </Container>
    </Layout>
  )
}

export default IndexPage
